
<template>
  <div class="container">

    <div class="live-detail">
      <div class="header">
        <img class="main-banner" v-if="video == ''" :src="cover" alt="">
        <video class="video-box" v-if="video" ref="myVideo" :src="video"  controls></video>

        <p class="title">{{title}}</p>
      </div>

      <div class="detail-interact">
        <div :class="{'active': is_collection == 1}" class="interact-item"  @click="createOperation('collection', info.id)">
          <i class="icon-star"></i>
          <span>{{info.collection}}</span>
        </div>
        <div class="interact-item">
          <i class="icon-view"></i>
          <span>{{info.view_num}}</span>
        </div>
        <div :class="{'active': is_spot == 1}" class="interact-item" @click="createOperation('spot', info.id)">
          <i class="icon-like"></i>
          <span>{{info.spot}}</span>
        </div>
<!--        <div class="btn">咨询客服</div>-->
      </div>

      <van-tabs class="has-bg-tabs" v-model="activeName" @click="changeTab()">
        <van-tab title="课程介绍" name="1">
          <div class="content-intro">
            <div class="rich-text" v-html="info.content"></div>
          </div> 
        </van-tab>
        <van-tab title="课程目录" name="2" v-if="info.course_type == 1">
          <div  class="content-catalogue">
            <p class="catalogue-item" v-for="(item, index) in courseList" :key="index"
               :class="{'active': index === twoIndex}"
               @click="twoCourse(index, item.title, item.video, item.cover)"
            >
              {{item.title}}
            </p>
          </div>
        </van-tab>
        <van-empty :description="word" v-if="commentList.length == 0 && activeName == 3" />
        <van-tab title="全部评价" name="3">
          <div class="appraise-full-box">
            <div class="content-appraise" v-if="commentList.length > 0">
              <van-list
                      v-model="loading"
                      :finished="finished"
                      finished-text="没有更多了"
                      @load="onRefresh"
              >
                <div class="appraise-item" v-for="(item, index) in commentList" :key="index">
                  <img class="appraise-user" :src="item.avatarurl" alt="">
                  <div class="appraise-detail">
                    <div class="appraise-detail-span">
                      <span>{{item.nickname}}</span>
                      <span>{{item.timeShow}}</span>
                    </div>
                    <p>{{item.content}}</p>
                  </div>
                </div>
              </van-list>
            </div>
            <div class="comment-input">
              <input placeholder="请输入评价内容" maxlength="200" @input="changeValue('content')"
                     v-model.trim="formData.content"/>
              <p class="btn" @click="createComment()">发送</p>
            </div>
          </div>
        </van-tab>
      </van-tabs>

    </div>
  </div>
</template>

<script>
// import cookies from "@/libs/util.cookies";
import {Dialog} from "vant";

export default {
  data(){
    return {
      activeName: '1',
      show: false,
      showLoading: false,
      info: {},
      courseList: [],
      commentList: [],
      formData: {
        'id': 0,
        'action': 'course',
        'content': '',
        'offset': 0,
        'limit': 10,
      },
      userinfo: {},
      twoIndex: -1,
      cover: '',
      title: '',
      video: '',
      is_collection: 0,
      is_spot: 0,
      word: '数据加载中...',
      loading: false,
      finished: false,
      refreshStatus: false
    }
  },
  created() {
    this.info = {}
    const id = this.$untils.getUrlKey('id');
    this.formData.id = id;
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.getInfo(id);
  },
  mounted() {
  },
  methods:{
    changeTab() {
      if (this.activeName == 3) {
        this.refreshStatus = false
        this.formData.offset = 0
        this.getComment()
      }
    },
    onRefresh() {
      this.getComment()
    },
    twoCourse(index, title, video, cover) {
      console.log(video)
      // this.$refs.myVideo.play();
      this.twoIndex = index
      this.title = title
      this.cover = cover
      this.video = video
    },
    //获取详情
    getInfo(id) {
      let that = this
      this.$api.COURSE_DETAIL_GET({
        'id': id
      }).then(res => {
        that.info = res
        that.title = that.info.title
        that.cover = that.info.cover
        that.video = that.info.video
        that.is_collection = Number(that.info.operation.collection)
        that.is_spot = Number(that.info.operation.spot)
        that.courseList = that.info.list
      })
    },
    //点赞OR收藏
    createOperation(field, id) {
      let that = this
      that.showLoading = true
      this.$api.CREATE_OPERATION_POST({'action': that.formData.action, 'field': field, 'id': id}).then(res => {
        if (!res.code) {
          if (field == 'collection') {
            if(Number(that.is_collection) == 0) {
              that.is_collection = 1
              that.info.collection++
            } else if (Number(that.is_collection) == 1) {
              that.is_collection = 0
              that.info.collection--
            }
          }
          if (field == 'spot') {
            if (Number(that.is_spot) == 0) {
              that.is_spot = 1
              that.info.spot++
            } else if (Number(that.is_spot) == 1) {
              that.is_spot = 0
              that.info.spot--
            }
          }
        }
        Dialog({ message: res.msg });
        that.showLoading = false
      })
    },
    //获取评论
    getComment() {
      let that = this
      if (that.refreshStatus) {
        return false
      }
      if (that.formData.offset == 0) {
        that.commentList = []
      }
      that.refreshStatus = true
      this.$api.LIST_COMMENT_POST(that.formData).then(res => {
        if (that.formData.offset > 0 && res.length == 0) {
          that.refreshStatus = false
          that.finished = true
        }
        if (res.length === 0) {
          that.refreshStatus = false
          that.word = '暂无数据'
          return false
        }
        res.forEach((item) => {
          that.commentList.push({
            id: item.id,
            nickname: item.nickname,
            content: item.content,
            avatarurl: item.avatarurl ? item.avatarurl : '',
            timeShow: item.create_time
          })
        })
        if (res.length >= that.formData.limit) {
          that.formData.offset = that.formData.offset + 15;
        } else {
          that.finished = true
        }
        that.loading =false
        that.refreshStatus = false
      })
    },
    //提交评论
    createComment() {
      let that = this
      if (that.showLoading == true) {
        return false
      }
      if (!that.formData.content) {
        Dialog({message: "请输入评论内容"})
        return
      }
      that.showLoading = true
      // console.log(that.formData, '提交参数')
      this.$api.CREATE_COMMENT_POST(that.formData).then(res => {
        if (!res.code) {
          that.commentList.unshift({'id': res.id, 'content': that.formData.content, 'nickname': that.userinfo.username, 'avatarurl': that.userinfo.avatarUrl, 'timeShow': res.create_time})
        }
        Dialog({ message: res.msg });
        that.formData.content = ''
        that.showLoading = false
      })
    },
    //文本验证
    changeValue() {
      let that = this
      let value = that.formData.content; //校验的字段
      // 中文、中文标点、全角字符按1长度，英文、英文符号、数字按0.5长度计算
      let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/;
      let maxLimitStr = 200; //25个汉字
      let pass = true;
      let substringStr = "";
      let length = 0;
      let strArr = value.split("");
      strArr.map((str) => {
        if (pass) {
          if (cnReg.test(str)) {//中文
            length++;
          } else {//英文
            length += 0.5;
          }
          //大于最大限制的时候
          if (length > maxLimitStr) {
            pass = false;
            Dialog({message: "文字长度已超出最大值，最大值为" + maxLimitStr * 2 + "个字符"})
            //将校验的字符截取返回
            that.formData.content = substringStr;
          } else {
            substringStr += str;
          }
        }
      });
      return pass;
    }
  }
}
</script>

<style lang='less' scoped>
  .live-detail .main-banner{
    display:block;
    width:100%;
    margin-top:10px;
  }

  .live-detail .title{
    display:block;
    font-size:19px;
    color:#000;
    padding-top:16px;
    padding-bottom: 24px;
  }

  .detail-interact{
    display:flex;
    align-items: center;
    margin-bottom:20px;
  }

  .interact-item{
    display:flex;
    align-items: center;
    margin-right:12px;
  }

  .interact-item i{
    width:28px;
    height:28px;
    margin-left:3px;
    &.icon-star{
      background:url(../../assets/images/detail/icon-star.png) no-repeat;
      background-size:  100% auto;
    }
    &.icon-view{
      background:url(../../assets/images/detail/icon-view.png) no-repeat;
      background-size:  100% auto;
    }
    &.icon-like{
      background:url(../../assets/images/detail/icon-like.png) no-repeat;
      background-size:  100% auto;
    }
  }

  .interact-item span{
    font-size:15px;
    color:#000;
  }

  .interact-item.active{
    span{
      color:#2580FE;
    }
    i.icon-star{
      background:url(../../assets/images/detail/icon-star-active.png) no-repeat;
      background-size:  100% auto;
    }
    i.icon-view{
      background:url(../../assets/images/detail/icon-view-active.png) no-repeat;
      background-size:  100% auto;
    }
    i.icon-like{
      background:url(../../assets/images/detail/icon-like-active.png) no-repeat;
      background-size:  100% auto;
    }
  }

  .detail-interact .btn{
    margin-left:auto;
    width:80px;
    height:32px;
    background:#26E0FD;
    font-size:14px;
    color:#fff;
    text-align: center;
    line-height:32px;
    border-radius:16px;
  }

  .content-catalogue{
    margin:16px 0;
    .catalogue-item{
      background: #F4F4F4;
      margin-bottom:12px;
      padding:12px;
      font-size:15px;
      color:#000;
      &.active{
        color:#2580FE;
      }
    }
  }

  .video-box{
    width:100%;
    max-width:100%;
  }

</style>